@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: 'Inter';
    @apply font-medium;
  }

  form {
    @apply flex flex-col w-full;
  }

  svg {
    @apply text-base;
  }

  h1 {
    @apply text-gray-900 text-3xl font-bold;
  }
}

/* Removes webpack bug in debug for some weird use cases */
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

:root {
  --toastify-icon-color-success: rgb(20, 184, 166); /* teal-500 */
  --toastify-color-progress-success: rgb(20, 184, 166); /* teal-500 */
}

/* CLEANUP ONCE FLOWBITE FIX IS DONE */
.react-datepicker__input-container {
  display: flex;
}

.react-datepicker__input-container input {
  flex-grow: 1;
  font-size: 16px;
  line-height: 24px;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  border-width: 1px;
  border-radius: 0.5rem;
  padding: 16px;
}

.react-datepicker__input-container input:disabled {
  @apply cursor-not-allowed opacity-50;
}

.react-datepicker__input-container input.md {
  padding: 8px;
}

.react-datepicker__input-container input.with-icon {
  padding-right: 16px;
}

.react-datepicker-fino-error {
  @apply border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500;
}

.react-datepicker__today-button {
  @apply bg-primary text-white;
}

.react-datepicker__header {
  @apply bg-white;
}

.react-datepicker__day--selected {
  @apply bg-primary text-white;
}

.react-datepicker__year-option:hover {
  @apply bg-primary-400 text-white;
}
.react-datepicker__year-option:first-of-type {
  position: relative;
}

.react-datepicker__year-option:first-of-type::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0;
  height: 0;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-bottom: 0.5rem solid currentColor; /* Arrow pointing downwards */
  transform: translate(-50%, -50%);
}

.react-datepicker__year-option:last-of-type {
  position: relative;
}

.react-datepicker__year-option:last-of-type::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0;
  height: 0;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-top: 0.5rem solid currentColor; /* Arrow pointing upwards */
  transform: translate(-50%, -50%);
}

.card-no-padding > *:first-child {
  padding: 0 !important;
}

.react-datepicker-popper {
  z-index: 20;
}
